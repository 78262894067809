/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React example components
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import UserRegistrationForm from "./UserRegistrationForm";
import Axios from "axios";
import Swal from "sweetalert2";
import MKButton from "../../components/MKButton";
import {List} from "@mui/material";

function SignUp() {
    const [first_name, setFirstName] = useState('');
    const [middle_name, setMiddleName] = useState('');
    const [last_name, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(false);

    const history = useNavigate();


    let apiURL = Config.apiURL;

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'first_name') {
            setFirstName(value);
        } else if (name === 'middle_name') {
            setMiddleName(value);
        } else if (name === 'last_name') {
            setLastName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'phone') {
            setPhone(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'confirm_password') {
            setConfirmPassword(value);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');

        if (password !== confirm_password){

            AlertMessages(102,`Password do not match`);
        }else {

            const payLoad = {
                firstName: first_name,
                middleName: middle_name,
                lastName: last_name,
                email: email,
                phone: phone,
                password: password
            }
            console.log(payLoad);
            registerUser(payLoad).then(r => false);

            // Reset form fields
            setPassword('');
            setEmail('');
            setPhone('');
        }
        // Close the modal
    };
    const registerUser = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'register-super-user', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: true
                })

                swalWithBootstrapButtons.fire({
                    title: 'Success',
                    text: message,
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        history('/login');
                    }
                })

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }

    return (
        <>

            <MKBox
                position="absolute"
                top={0}
                left={0}
                zIndex={1}
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">

                    <Grid item xs={11} sm={9} md={5} lg={4} xl={4}>

                        <Card>
                            <MKBox
                                variant="gradient"
                                bgColor="secondary"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Register User/ Sajili mtumiaji
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={3} px={3}>
                                {errors && errors.map((record, index) => <List>
                                    <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                                  fontWeight="light">
                                        <b>{record.label}:&nbsp;</b>{record.value}<br/>
                                    </MKTypography>
                                </List>)}
                                <UserRegistrationForm password={password} confirm_password={confirm_password} email={email} first_name={first_name} last_name={last_name} phone={phone} middle_name={middle_name} handleChange={handleParticipantChange}/>
                            </MKBox>

                            <MKBox pt={0} pb={3} px={3}>
                                <MKButton sx={{width:'100%'}} onClick={handleSubmit} variant="gradient" color="secondary">
                                    Register / Sajili
                                </MKButton>
                            </MKBox>

                        </Card>
                    </Grid>
                </Grid>
            </MKBox>

        </>
    );
}

export default SignUp;
