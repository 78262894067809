/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import {CircularProgress, List} from "@mui/material";
import AlertMessages from "../../utils/AlertMessage";
import {Config} from "../../utils/Config";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import Axios from "axios";
import SearchForm2 from "./SearchForm2";
import IdentificationForm from "./IndentificationForm";
import DefaultInfoCard from "../../examples/Cards/InfoCards/DefaultInfoCard";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

function Identifications({handleOpen, sponsors, events}) {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tin, setTin] = useState('');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState(0);
    const [name, setName] = useState('');
    const [mode, setMode] = useState('Cash');
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    const [registration, setRegistration] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [searchValue, setSearchValue] = useState('');


    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'phone') {
            setPhone(value);
        }
    };


    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            name: name, phone: phone, created_by: profile.id, season_id: events, reference_no: registration.reference_no
        }
        console.log(payLoad);
        registerIdentification(payLoad).then(r => false);

        // Reset form fields
        setName('');
        setPhone('');
        setAmount('');
        setTin('');
        // Close the modal
    };

    const registerIdentification = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'create-identification', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
            handleSubmitSearch();
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                AlertMessages(status, message);
            } else {
                AlertMessages(104, message);
            }

            setLoading(false)
        }
    }

    const handleSubmitSearch = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            searchValue: searchValue
        }
        console.log(payLoad);
        getRegistration(payLoad).then(r => false);

        // Close the modal
    };

    const getRegistration = async (payLoad) => {
        setLoading(true)
        const res = await Axios.post(apiURL + 'get-registration', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setRegistration(res.data.data.registration);
                setIdentifications(res.data.data.identifications);
                const payLoad = {
                    searchValue: searchValue
                }
                getRegistration(payLoad).then(r => false);

            } else {
                AlertMessages(status, message);
            }
            setLoading(false)
        }
    }

    const handleSearchChange = (e) => {
        const {name, value} = e.target;
        if (name === 'search_value') {
            setSearchValue(value);
        }
    };

    useEffect(() => {
        const maskPattern = "MG9999-999-999-999";
        const maskLength = maskPattern.replace(/[^9]/g, "").length;
        const valuePattern = searchValue.replace('_', "");
        const valueLength = valuePattern.replace(/-/g, "").length;
        if (valueLength === maskLength + 2) {
            // Call your submit function here
            handleSubmitSearch();
        } else {
            setRegistration(null);
        }
    }, [searchValue]);

    useEffect(() => {
        setRegistration(registration)
    }, [registration]);

    const openExternalLink = (e) => {
        const id = e.currentTarget.getAttribute('id');
        const link = `https://madinigeita.co.tz/maonesho-api/public/api/participant-id/${id}`;
        window.open(link, '_blank');
    };
    return (
        <>


            <Container sx={{mt:8}}>


                <Grid container item xs={11} spacing={3} alignItems="center" sx={{mx: "auto"}}>
                    <Grid item xs={12} lg={12} sx={{ml: "auto", mt: -10}}>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={4}>
                                <SearchForm2 searchValue={searchValue} handleChange={handleSearchChange}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                {!loading && registration && <>
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                                        <MKTypography variant="body2">Participant : <b>{registration.name}</b></MKTypography><br/>
                                    </MKBox>
                                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -5}}>
                                        <MKTypography variant="body2">Reference: <b>{registration.reference_no}</b></MKTypography>
                                    </MKBox>
                                    <Divider sx={{mt:-2}}/>
                                    {!loading && identifications && identifications.map((item, index) => <>
                                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt:-2}}>
                                            <MKTypography variant="caption">SN : <b>{index + 1}</b></MKTypography>
                                        </MKBox>
                                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -4}}>
                                            <MKTypography variant="caption">NAME: <b>{item.name}</b></MKTypography>
                                            <Button sx={{mt:-2}} id={item.reference_id} variant="text" color="primary" onClick={openExternalLink}>
                                                View ID
                                            </Button>
                                        </MKBox>
                                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -4}}>
                                            <MKTypography variant="caption">ID : <b>{item.reference_id}</b></MKTypography>
                                        </MKBox>


                                    </>)}
                                </>}
                            </Grid>

                            <Grid item xs={12} md={4}>
                                {!loading && registration && <MKBox p={2}>
                                    <MKTypography variant="body2">Create New ID</MKTypography>

                                    <IdentificationForm phone={phone}
                                                        name={name}
                                                        handleChange={handleParticipantChange}
                                    />
                                </MKBox>}
                                <Divider/>
                                {loading && <CircularProgress/>}

                                <Divider sx={{my: 0}}/>
                                {!loading && registration &&

                                    <MKBox display="flex" justifyContent="space-between" p={1.5}>

                                        <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                                            Save / Hifadhi
                                        </MKButton>
                                    </MKBox>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Container>


        </>
    );
}

export default Identifications;
