/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React from "react";
import MKButton from "../../components/MKButton";
import {CircularProgress, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {AddAlertOutlined} from "@mui/icons-material";
import {Divider} from "antd";

function Events({handleOpen, events, loading}) {

    return (
        <div className="px-2">

            <div className="px-2">
                <MKButton color='warning' type='default' onClick={handleOpen}
                          style={{float: 'right'}}><AddAlertOutlined/>&nbsp; New
                    Event</MKButton>

            </div>
            <Divider className="mt-1"/>
            <div className="mt-2">

            {loading && <CircularProgress/>}
            {events && Number(events.length) > 0 &&
                <TableContainer>
                    <Table aria-label="simple table">
                        <MKBox color='dark' component="thead">
                            <TableRow>
                                <TableCell>
                                    <b>SN</b>
                                </TableCell>
                                <TableCell>
                                    <b>CODE</b>
                                </TableCell>
                                <TableCell>
                                    <b>Title</b>
                                </TableCell>
                                <TableCell>
                                    <b>Location</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>From Date</b>
                                </TableCell>

                                <TableCell align="center">
                                    <b>To Date</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>Current Event</b>
                                </TableCell>

                            </TableRow>
                        </MKBox>
                        <MKBox color='text' component="tbody">
                            {events && events.map((event, index) =>
                                <TableRow color='dark' key={event.id}>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='secondary' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {index + 1}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='info' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.code}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.title}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell id={index}>
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.location}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.start_date}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.end_date}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            {event.active}
                                        </MKTypography>
                                    </TableCell>


                                </TableRow>
                            )
                            }

                        </MKBox>
                    </Table>
                </TableContainer>
            }
            </div>
        </div>
    );
}

export default Events;
