import {Config} from '../utils/Config';

function GetProfile() {
    const val = localStorage.getItem('profile');
    if (val !== 'undefined') {
        return JSON.parse(val);
    }
    return {};
}

function GetToken() {
    const val = localStorage.getItem('accessToken');

    if (val !== 'undefined') {
        return JSON.parse(val);
    }
    return {};
}

function GetIsAdmin() {
    const val = localStorage.getItem('isAdmin');

    if (val !== 'undefined') {
        return JSON.parse(val);
    }
    return {};
}
function GetTokenHeaders() {
    const val = localStorage.getItem('accessToken');
    if (val !== 'undefined') {
        return {
            headers: {
                Authorization: `Bearer ${val}`,
            }
        }
    }
    return {};
}

function GetCurrentRole() {
    const val = localStorage.getItem('currentRole');

    if (val !== 'undefined') {
        return JSON.parse(val);
    }
    return {};
}

function GetRoles() {
    const val = localStorage.getItem('roles');

    if (val !== 'undefined') {
        return JSON.parse(val);
    }
    return {};
}

function CheckSession(profile) {
    return !(profile === "" || profile === null);
}

const backHome = async () => {
    window.location.replace(Config.intranetURL);
}

async function logout() {
    return new Promise((resolve) => {
        setTimeout(() => {
            localStorage.removeItem('LoginToken');
            localStorage.removeItem('profile');
            resolve();
            backHome();
        }, 1000);
    });
}
const getNum = str => /[-+]?[0-9]*\.?[0-9]+/.test(str) ? parseFloat(str) : 0;

const numFormat = (n) => {
    // Convert the value to a number if it's a string
    const numericValue = typeof n === 'string' ? parseFloat(n) : n;

    // Round to two decimal places
    const roundedValue = Math.round(numericValue * 100) / 100;

    // Use toFixed to always display two decimal places
    const formattedValue = roundedValue.toFixed(2);

    // Add comma separators for thousands
    const parts = formattedValue.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Join the parts and return the formatted value
    return parts.join(".");
};

export {
    GetProfile, logout, backHome, CheckSession, GetToken, GetCurrentRole, GetRoles, GetIsAdmin,GetTokenHeaders,getNum,numFormat
}