import React from 'react';
import {Card, CardContent, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import InputMask from 'react-input-mask';
import MKTypography from "../../components/MKTypography";
import {Link} from "react-router-dom";
import MKButton from "../../components/MKButton";

const SearchForm2 = ({searchValue, handleChange}) => {

    return (<Card>
        <CardContent>
            <Grid container spacing={2} sx={{width: '350px'}}>

                <Grid item xs={12}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <MKTypography variant='body2' sx={{ mt: 1, mb: 3 }} color='dark'>
                            Ingiza namba ya malipo
                        </MKTypography>
                        <InputMask
                            mask="MG9999-999-999-999"
                            value={searchValue}
                            onChange={handleChange}
                        >
                            {() => (
                                <TextField
                                    label="Namba ya malipo / Reference No"
                                    name="search_value"
                                    fullWidth
                                />
                            )}
                        </InputMask>
                    </div>
                </Grid>


            </Grid>
        </CardContent>
    </Card>);
};

export default SearchForm2;