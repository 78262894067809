import React from "react";

import {Divider} from "antd";

import {Link} from "react-router-dom";
import appLogo from "../../assets/images/logo/tz.png";


function HeaderAdmin() {

    return (

            <div className="fixed h-16 top-0 left-0 w-full bg-gray-500 rounded-b-xl p-4 z-50">
                <div className="grid grid-cols-11">
                    <div className="col-span-1">
                        <Link to='/'>
                            <img
                                style={{boxShadow: '0 1px 4px rgba(0,0,0,.3), inset 0 0 40px rgba(0,0,0,.1)'}}
                                src={appLogo}
                                className="mr-7 -mt-4 h-16 w-16 rounded-full shadow-[#365e7d] shadow-md"
                                alt="Flowbite Logo"
                            />
                        </Link>
                    </div>
                    <div className="col-span-9 ml-16 justify-center text-white -mt-3 font-bold text-xl">GEITA MINERAL
                        EXHIBITION MANAGEMENT INFORMATION SYSTEM (GeMEMIS)
                        <Divider className="mt-1 mb-1"/>
                        <div className="flex text-sm col-span-10 justify-center text-gray-200 ">
                            Exhibition & Participants Management
                        </div>
                        <div className="flex justify-end -mt-5">
                            <div className="flex col-span-10 justify-end text-gray-200 ">
                                <Link to='/admin/system-admin/sign-out'
                                      className="ml-2 bg-gray-600 text-white text-sm px-2 py-0 rounded-md">Log
                                    out</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <Link to='/'>
                            <img
                                style={{boxShadow: '0 1px 4px rgba(0,0,0,.3), inset 0 0 40px rgba(0,0,0,.1)'}}
                                src={appLogo}
                                className="ml-10 -mt-4 h-16 w-16 rounded-full shadow-[#365e7d] shadow-md"
                                alt="Flowbite Logo"
                            />
                        </Link>
                    </div>

                </div>

            </div>

    );
}

export default HeaderAdmin;
