import React, {useEffect, useState} from 'react';
import {List, Modal} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import BusinessTypeForm from "./BusinessTypeForm";
import ReceiptUpload from "./UploadReceipts";

const RegistrationModal = ({open, handleClose, referenceNo}) => {

    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>
            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                <MKBox
                    position="relative"
                    width="450px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Weka risiti ya malipo</MKTypography>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleClose}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <ReceiptUpload referenceNo={referenceNo} handleClose={handleClose}/>
                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default RegistrationModal;