import React from 'react';
import {
    Card,
    CardContent,
    CircularProgress,
    FormControlLabel,
    InputLabel,
    Radio,
    RadioGroup,
    Select,
    TextField
} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProductList from "./ProductList";
import MenuItem from "@mui/material/MenuItem";
import InputMask from 'react-input-mask';
import ReactHtmlParser from 'react-html-parser';
import MKTypography from "../../components/MKTypography";

const EditParticipantForm = ({
                             tin,
                             name,
                             phone,
                             handleChange,
                             amount,
                             benefits
                         }) => {


    return (<Card>
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <InputLabel>Edit Participant Details/Sasisha taarifa za mshiriki</InputLabel>


                    <TextField sx={{mt: 1}}
                               name="name"
                               value={name}
                               disabled
                               onChange={handleChange}
                               fullWidth
                    />
                    <Grid item xs={12}>

                        <TextField sx={{mt: 1}}
                                   name="tin"
                                   value={tin}
                                   disabled
                                   onChange={handleChange}
                                   fullWidth
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField sx={{mt: 1}}
                                   name="phone"
                                   value={phone}
                                   disabled
                                   onChange={handleChange}
                                   fullWidth
                        />
                    </Grid>
                    <TextField sx={{mt: 1}}
                               label="Amount"
                               name="amount"
                               value={amount}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 2}}
                               label="Benefits/Stahiki"
                               name="benefits"
                               value={benefits}
                               onChange={handleChange}
                               fullWidth
                               multiline minRows='2'
                    />
                </Grid>

            </Grid>
            <Divider/>

        </CardContent>
    </Card>);
};

export default EditParticipantForm;