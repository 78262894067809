/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress} from "@mui/material";
import BusinessTypesModal from "./BusinessTypesModal";
import PaymentApprovalModal from "./PaymentApprovalModal";
import DashboardCounters from "./DashboardCounters";
import ReportDetails from "./ReportDetails";
import Swal from "sweetalert2";

function Reports({currentReportType}) {
    let [loading, setLoading] = useState(false);
    let [report, setReport] = useState([]);
    let [excelReport, setExcelReport] = useState([]);
    let [reportId, setReportId] = useState('');
    let [reportType, setReportType] = useState('');

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getReport = async () => {
        const payLoad = {
            report: currentReportType,
            created_by: profile.id
        }
        setLoading(true)
        const res = await Axios.post(apiURL + 'report',payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                AlertMessages(status, message);
                setReport(res.data.data.report);
                setReportId(res.data.data.report_id);
                setReportType(res.data.data.report_type);
            }

            setLoading(false)
        }
    }
    const downloadReport = async () => {
        const payLoad = {
            report: reportId
        }
        setLoading(true)
        const res = await Axios.post(apiURL + 'report-excel',payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setReportType(res.data.data.report_type);
                setReport(res.data.data.report);

                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger'
                    }, buttonsStyling: true
                })

                swalWithBootstrapButtons.fire({
                    title: 'Success', text: message, icon: 'success', showCancelButton: false, confirmButtonText: 'Ok',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await getReport();
                    }
                })
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getReport().then(r => false);
    }, []);

    useEffect(() => {
        setReport(report);
        setReportId(reportId);
        setReportType(reportType)
    }, [report,reportType,reportId]);



    return (
        <>

            <Grid
                container
                item
                xs={12}
                lg={12}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{mx: "auto", textAlign: "center"}}
            >
                <Divider/>

                {loading && <CircularProgress/>}
                <Container sx={{mt: -8}}>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={12}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{textAlign: "center"}}
                    >
                        <Divider/>
                        {!loading && <ReportDetails report={report} downloadReport={downloadReport} reportType={reportType}/>}
                    </Grid>
                </Container>

            </Grid>

        </>
    );
}

export default Reports;
