/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import bgImage from "assets/images/bg-about-us.jpg";
import SearchForm from "./SearchForm";
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import ViewRegistrationForm from "./ViewRegistrationForm";
import {CardContent, CircularProgress} from "@mui/material";
import MKButton from "../../components/MKButton";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import Information from "../Presentation/sections/Information";
import ReceiptUploadModal from "./ReceiptUploadModal";

function RegistrationDetails() {
    const [searchValue, setSearchValue] = useState('');
    let [loading, setLoading] = useState(false);
    let [documentLinks, setDocumentLinks] = useState([]);
    let [registrationStatus, setRegistrationStatus] = useState('');
    let [referenceNo, setReferenceNo] = useState('');
    const [open, setOpen] = useState(false);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setDocumentLinks(documentLinks);
        setRegistrationStatus(registrationStatus);
        setReferenceNo(referenceNo);
    }, [documentLinks]);

    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'search') {
            setSearchValue(value);
        }
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        const payLoad = {
            searchValue: referenceNo,
        }
        getRegistration(payLoad).then(r => false)
    };
    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            searchValue: searchValue,
        }
        console.log(payLoad);
        // Reset form fields
        setSearchValue('');
        getRegistration(payLoad)
        // Close the modal
    };

    const getRegistration = async (payLoad) => {
        setLoading(true)
        const res = await Axios.post(apiURL + 'view-registration', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                const links = res.data.data.documents;
                const regStatus = res.data.data.registration_status;
                const ref = res.data.data.reference_no;
                setDocumentLinks(links);
                setRegistrationStatus(regStatus)
                setReferenceNo(ref)
            } else {
                AlertMessages(status, message)
            }
            setLoading(false)
        }
    }
    return (
        <>

            <MKBox
                minHeight="40vh"
                width="100%"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                            sx={({breakpoints, typography: {size}}) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Taarifa za mshiriki / Exhibitor's details
                        </MKTypography>
                        <Divider/>
                        <SearchForm searchValue={searchValue} handleChange={handleParticipantChange}
                                    handleSubmit={handleSubmit}/>

                    </Grid>
                </Container>
                {loading && <CircularProgress/>}
            </MKBox>

            {documentLinks.registration_form &&
                <MKBox pt={1} px={1} mt={2}>
                    <MKBox sx={{float: 'right', mt: -3, mb: -1, mr: 6}}>
                        {!documentLinks.payment_form && <MKButton sx={{mt: 1}} onClick={handleOpen} variant="gradient" color="secondary">
                            Weka risiti ya malipo
                        </MKButton>
                        }

                    </MKBox>
                    <MKBox sx={{float: 'right', mt: -1, mb: -1, mr: 2}}>
                        <MKTypography variant='body2' color='dark'>
                            Hali ya Usajlii: <b>{registrationStatus}</b>
                        </MKTypography>
                    </MKBox>
                    <CardContent>
                        <Grid container spacing={1}>

                            <Grid item xs={12}>
                                <ViewRegistrationForm
                                    documentLink={documentLinks.registration_form} message='Hakuna taarifa za usajili'/>
                            </Grid>
                            <Grid sx={{mt:-8}} item xs={12}>
                                <ViewRegistrationForm
                                    documentLink={documentLinks.payment_form} message='Hakuna taarifa za malipo'/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </MKBox>
            }
                <Information/>
            {open && <ReceiptUploadModal handleClose={handleClose} open={open} referenceNo={referenceNo}/>}

        </>
    );
}

export default RegistrationDetails;
