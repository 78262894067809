/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components

// @mui material components

// @mui icons
// Material Kit 2 React components
// Material Kit 2 React example components
// Material Kit 2 React page layout routes
// Images
import React from "react";
import Divider from "@mui/material/Divider";
import MKTypography from "../../components/MKTypography";

function ViewRegistrationForm({
                                  documentLink, message
                              }) {

    return (
        <div>


            {documentLink && <iframe
                src={documentLink}
                title="Embedded Document"
                width="100%"
                height="600"
            ></iframe>
            }
            {!documentLink && <MKTypography sx={{mt: 2}} variant='body2' color='error'>
                {message}
            </MKTypography>}

        </div>
    );
}

export default ViewRegistrationForm;
