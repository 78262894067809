import React, {useState} from 'react';
import {InputLabel, Select, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import InputMask from 'react-input-mask';
import Axios from "axios";
import AlertMessages from "../../utils/AlertMessage";
import {GetTokenHeaders} from "../../common/ReusableFunction";
import {Config} from "../../utils/Config";

const SponsorForm = ({name, tin, phone, email, address, contact_person, mode, amount, handleChange, business,presetValues}) => {
    const categoryOptions = ['Cash', 'Inkind'];
    let [sponsor, setSponsor] = useState([]);
    let [showRegistration, setShowRegistration] = useState(false);
    let [editMode, setEditMode] = useState(false);
    let [loading, setLoading] = useState(false);

    let headers = GetTokenHeaders();
    let apiURL = Config.apiURL;


    const checkRegistration = async () => {
        setLoading(true)
        const ref = tin?tin:phone;
        const res = await Axios.get(apiURL + `get-sponsor/${ref}`, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            const data = res.data.data.sponsor;
            if (status === 100) {
                setSponsor(res.data.data.sponsor)
                setShowRegistration(true);
                presetValues(data);

                if (data !== null){
                    setEditMode(true)
                }else{
                    setEditMode(false)

                }
            }
            setLoading(false)
        }

    }

    return (<div>
        <Grid container spacing={2}>
            <Grid item xs={4} mt={0}>
                <InputMask
                    mask="999-999-999"
                    value={tin}
                    onChange={handleChange}
                >
                    {() => (<TextField
                        label="TIN"
                        name="tin"
                        fullWidth
                    />)}
                </InputMask>

            </Grid>
            <Grid item xs={4} mt={0}>

                <InputMask
                    mask="255 999 999 999"
                    value={phone}
                    onChange={handleChange}
                >
                    {() => (<TextField
                        label="Phone/Simu"
                        name="phone"
                        fullWidth
                    />)}
                </InputMask>
            </Grid>
            <Grid item xs={4} mt={0}>
                <button onClick={checkRegistration}
                        className="border-0 bg-gray-400 px-2 py-2 rounded-lg hover:bg-gray-300">Proceed
                </button>
            </Grid>

            {showRegistration &&
                <>
                    <Grid item xs={12}>

                        <TextField sx={{mt: 1}}
                                   label="Name/Jina la taasisi au kampuni"
                                   name="name"
                                   value={name}
                                   onChange={handleChange}
                                   fullWidth
                                   disabled={editMode}
                        />
                        <TextField sx={{mt: 1}}
                                   label="Sponsor Business"
                                   name="business"
                                   value={business}
                                   onChange={handleChange}
                                   fullWidth
                        />
                        <TextField sx={{mt: 1}}
                                   label="Address/Anuani"
                                   name="address"
                                   value={address}
                                   onChange={handleChange}
                                   fullWidth
                                   disabled={editMode}
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField sx={{mt: 0}}
                                   name="email"
                                   label="Email/Barua pepe"
                                   type='email'
                                   value={email}
                                   onChange={handleChange}
                                   fullWidth
                                   disabled={editMode}
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField sx={{mt: 0}}
                                   name="contact_person"
                                   label="Contact person/Mwakilishi"
                                   value={contact_person}
                                   onChange={handleChange}
                                   fullWidth
                                   disabled={editMode}
                        />

                    </Grid>

                    <Grid item xs={6} mt={0}>


                        <InputLabel>Mode/Aina</InputLabel>
                        <Select fullWidth sx={{height: 40, mt: 1}}
                                name="mode" value={mode} onChange={handleChange}>
                            <MenuItem value="" disabled>
                                Select a mode
                            </MenuItem>
                            {categoryOptions.map((option) => (<MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>))}
                        </Select>
                    </Grid>

                    <Grid item xs={6} mt={0}>

                        <TextField sx={{mt: 2.2}}
                                   name="amount"
                                   type="number"
                                   label="Amount/Kiasi"
                                   value={amount}
                                   onChange={handleChange}
                                   fullWidth
                        />

                    </Grid>
                </>
            }

        </Grid>

    </div>);
};

export default SponsorForm;