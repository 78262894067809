import React from 'react';
import {Card, CardContent, InputLabel, Select, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProductList from "./ProductList";
import MenuItem from "@mui/material/MenuItem";
import InputMask from 'react-input-mask';

const EventForm = ({title, start_date, end_date, location,handleChange}) => {

    return (<Card>
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <TextField sx={{mt: 1}}
                               label="Title/Tukio"
                               name="title"
                               value={title}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Location/Mahali pa Tukio"
                               name="location"
                               value={location}
                               onChange={handleChange}
                               fullWidth
                    />

                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={{ml:1}}>End Date/Tarehe ya kuanza</InputLabel>

                    <TextField sx={{mt: 1}}
                               name="start_date"
                               type='date'
                               value={start_date}
                               onChange={handleChange}
                               fullWidth
                    />

                </Grid>

                <Grid item xs={6}>
                    <InputLabel sx={{ml:1}}>End Date/Tarehe ya kumaliza</InputLabel>

                    <TextField sx={{mt: 1}}
                               name="end_date"
                               type='date'
                               value={end_date}
                               onChange={handleChange}
                               fullWidth
                    />

                </Grid>

            </Grid>

        </CardContent>
    </Card>);
};

export default EventForm;