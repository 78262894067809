/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress} from "@mui/material";
import DashboardCounters from "./DashboardCounters";

function Dashboard({setCurrentTab, setCurrentReportType}) {
    let [loading, setLoading] = useState(false);
    let [dashboard, setDashboard] = useState([]);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getDashboards = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'dashboard', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setDashboard(res.data.data.dashboard);
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getDashboards().then(r => false);
    }, []);

    useEffect(() => {
        setDashboard(dashboard);
    }, [dashboard]);


    return (
        <div className="bg-white py-4 ml-2 mr-2 rounded-lg mt-2">

            {loading && <CircularProgress/>}


                    <DashboardCounters setCurrentTab={setCurrentTab} dashbaord={dashboard}
                                       setCurrentReportType={setCurrentReportType}/>



        </div>
    );
}

export default Dashboard;
