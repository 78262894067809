import Swal from "sweetalert2";


const AlertMessages = (errorCode, message, messageTimer = null) => {
    let messageTitle = '';
    let messageIcon = '';
    if (errorCode === 0) {
        messageTitle = 'Authentication';
        messageIcon = 'error';
    } else if (errorCode === 100) {
        messageTitle = 'Success';
        messageIcon = 'success';
    } else if (errorCode === 101) {
        messageTitle = 'Success';
        messageIcon = 'success';
    } else if (errorCode === 102) {
        messageTitle = 'Required Params';
        messageIcon = 'error';
    } else if (errorCode === 103) {
        messageIcon = 'info';
    }
    else if (errorCode === 109) {
        messageTitle = 'Karibu!';
        messageIcon = 'info';
    }
    else if (errorCode === 104) {
        messageTitle = 'Restricted';
        messageIcon = 'warning';
    } else if (errorCode === 105) {
        messageTitle = 'Exception';
        messageIcon = 'error';
    } else if (errorCode === 404) {
        messageTitle = 'General Error';
        messageIcon = 'error';
    }

    if (errorCode === 100) {
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: messageTitle,
            text: message,
            showConfirmButton: false,
            timer: 1000
        })
    } else if (messageTimer === null) {
        Swal.fire({
            title: messageTitle,
            text: message,
            icon: messageIcon,
            confirmButtonText: 'Ok',
        }).then()
    } else if (true) {
        Swal.fire({
            title: messageTitle,
            text: message,
            icon: messageIcon,
            confirmButtonText: 'Ok',
            timer: messageTimer,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        }).then()
    }

}


export default AlertMessages;
