import React, {useEffect, useState} from 'react';
import {List, Modal} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import Swal from "sweetalert2";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import SponsorForm from "./SponsorForm";
import SearchForm from "./SearchForm";

const SponsorModal = ({open, handleClose, getSponsors}) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [contact_person, setContactPerson] = useState('');
    const [tin, setTin] = useState('');
    const [business, setBusiness] = useState('');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState(0);
    const [name, setName] = useState('');
    const [mode, setMode] = useState('Cash');
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    let [currentEvent, setCurrentEvent] = useState([]);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'address') {
            setAddress(value);
        } else if (name === 'phone') {
            setPhone(value);
        } else if (name === 'contact_person') {
            setContactPerson(value);
        }else if (name === 'email') {
            setEmail(value);
        }else if (name === 'tin') {
            setTin(value);
        }else if (name === 'amount') {
            setAmount(value);
        }else if (name === 'mode') {
            setMode(value);
        }else if (name === 'business') {
            setBusiness(value);
        }
    };

    const presetValues =(values)=>{
        if (values){
            setName(values.name);
            setAddress(values.address);
            setPhone(values.phone);
            setContactPerson(values.contact_person);
            setEmail(values.email);
            setTin(values.tin);
            setBusiness(values.business);
            setAmount('');
        }else{
            setName('');
            setAddress('');
            setPhone('');
            setContactPerson('');
            setEmail('');
            setTin('');
            setBusiness('');
            setAmount('');
        }
    }

    useEffect(()=>{
        presetValues(null);
    },[])





    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            name: name,
            address: address,
            phone: phone,
            contact_person: contact_person,
            email: email,
            tin: tin,
            amount: amount,
            mode: mode,
            created_by: profile.id,
            season_id: currentEvent,
            business: business
        }
        console.log(payLoad);
        registerSponsor(payLoad).then(r => false);

        // Reset form fields
        setName('');
        setPhone('');
        setAmount('');
        setTin('');
        // Close the modal
    };

    const getCurrentEvent = async () => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-current-event', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setCurrentEvent(res.data.data.events)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getCurrentEvent().then(r => false);
    }, [open]);

    const registerSponsor = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'create-sponsor', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                await getSponsors();
                handleClose();
                AlertMessages(status,message);
            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }


    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>

            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>

                <MKBox
                    position="relative"
                    width="650px"
                    maxHeight="99vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Add Sponsor/ Ongeza madhamini</MKTypography>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleClose}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    {errors && errors.map((record, index) => <List>
                        <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                      fontWeight="light">
                            <b>{record.label}:&nbsp;</b>{record.value}<br/>
                        </MKTypography>
                    </List>)}
                    <MKBox p={2}>
                        <SponsorForm email={email} phone={phone} mode={mode} address={address} amount={amount} name={name} contact_person={contact_person} tin={tin} business={business} presetValues={presetValues}
                            handleChange={handleParticipantChange}
                        />
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    {name &&
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                            close
                        </MKButton>
                        <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                            Save / Hifadhi
                        </MKButton>
                    </MKBox>
                    }

                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default SponsorModal;