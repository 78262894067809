import React, {useState} from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKButton from "../../components/MKButton";
import MKTypography from "../../components/MKTypography";
import TextField from "@mui/material/TextField";
import {Config} from "../../utils/Config";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";


const ReceiptUpload = ({referenceNo, handleClose}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [base64String, setBase64String] = useState('');
    let [loading, setLoading] = useState(false);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // File type validation
        if (file && file.type !== 'application/pdf') {
            alert('Please select a PDF file.');
            return;
        }
        // File size validation
        if (file && file.size > 2 * 1024 * 1024) {
            alert('File size should be less than 2MB.');
            return;
        }
        setSelectedFile(file);
        convertToBase64(file);
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setBase64String(reader.result);
        };
    };

    const handleFileUpload = () => {
        if (selectedFile) {
            const payLoad = {
                documentFile: base64String,
                referenceNo: referenceNo
            }
            // Send the base64 string to the backend
            submitFile(payLoad).then(r => false);
        }
    };

    const submitFile = async (payLoad) => {
        setLoading(true)
        const res = await Axios.post(apiURL + 'upload-receipt', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;

            AlertMessages(status, message)

            setLoading(false);
            handleClose();
        }
    }


    return (
        <div>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={12}
                    justifyContent="left"
                    alignItems="left"
                    flexDirection="column"
                    sx={{mx: "auto", textAlign: "center"}}
                >
                    <MKTypography color='dark' variant="body2"
                                  fontWeight="small"
                                  textTransform="capitalize"
                                  sx={{mb: 1, mt: 1}}>
                        Chagua risiti yako ya malipo
                    </MKTypography>
                    <TextField fullWidth type="file" accept="application/pdf" onChange={handleFileChange}/>
                    <MKButton sx={{mt: 2, mb: 2}} variant="contained" color="success" onClick={handleFileUpload}>
                        Upload
                    </MKButton>
                </Grid>
            </Container>
        </div>
    );
};

export default ReceiptUpload;