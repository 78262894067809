import React, {useEffect, useState} from 'react';
import {CircularProgress, List, Modal} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import SearchForm2 from "./SearchForm2";
import IdentificationForm from "./IndentificationForm";

const IdentificationsModal = ({open, handleClose, events, getIdentifications}) => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [contact_person, setContactPerson] = useState('');
    const [tin, setTin] = useState('');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState(0);
    const [name, setName] = useState('');
    const [mode, setMode] = useState('Cash');
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    const [registration, setRegistration] = useState([]);
    const [identifications, setIdentifications] = useState([]);
    const [searchValue, setSearchValue] = useState('');


    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'phone') {
            setPhone(value);
        }
    };


    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            name: name, phone: phone, created_by: profile.id, season_id: events, reference_no: registration.reference_no
        }
        console.log(payLoad);
        registerIdentification(payLoad).then(r => false);

        // Reset form fields
        setName('');
        setPhone('');
        setAmount('');
        setTin('');
        // Close the modal
    };

    const registerIdentification = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'create-identification', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                await getIdentifications();
                getIdentifications()
                AlertMessages(status, message);
            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }

    const handleSubmitSearch = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            searchValue: searchValue
        }
        console.log(payLoad);
        getRegistration(payLoad).then(r => false);

        // Close the modal
    };

    const getRegistration = async (payLoad) => {
        setLoading(true)
        const res = await Axios.post(apiURL + 'get-registration', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setRegistration(res.data.data.registration);
                setIdentifications(res.data.data.identifications);
            } else {
                AlertMessages(status, message);
                handleClose();
            }
            setLoading(false)
        }
    }

    const handleSearchChange = (e) => {
        const {name, value} = e.target;
        if (name === 'search_value') {
            setSearchValue(value);
        }
    };

    useEffect(() => {
        const maskPattern = "MG9999-999-999-999";
        const maskLength = maskPattern.replace(/[^9]/g, "").length;
        const valuePattern = searchValue.replace('_', "");
        const valueLength = valuePattern.replace(/-/g, "").length;
        if (valueLength === maskLength + 2) {
            // Call your submit function here
            handleSubmitSearch();
        } else {
            setRegistration(null);
        }
    }, [searchValue]);

    useEffect(() => {
        setRegistration(registration)
    }, [registration]);

    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>

            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>

                <MKBox
                    position="relative"
                    width="450px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Participant Identifications / Vitambulisho</MKTypography>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleClose}/>
                    </MKBox>
                    <SearchForm2 searchValue={searchValue} handleChange={handleSearchChange}/>
                    <Divider sx={{my: 0}}/>
                    {errors && errors.map((record, index) => <List>
                        <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                      fontWeight="light">
                            <b>{record.label}:&nbsp;</b>{record.value}<br/>
                        </MKTypography>
                    </List>)}

                    {!loading && registration && <>
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                            <MKTypography variant="body2">Participant : <b>{registration.name}</b></MKTypography><br/>
                        </MKBox>
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -5}}>
                            <MKTypography variant="body2">Reference: <b>{registration.reference_no}</b></MKTypography>
                        </MKBox>
                    </>}
                    <Divider sx={{mt: -2}}/>

                    {!loading && identifications && identifications.map((item, index) => <>
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                            <MKTypography variant="caption">SN : <b>{index + 1}</b></MKTypography>
                        </MKBox>
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -4}}>
                            <MKTypography variant="caption">ID : <b>{item.id}</b></MKTypography>
                        </MKBox>
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2} sx={{mt: -4}}>
                            <MKTypography variant="caption">NAME: <b>{item.name}</b></MKTypography>
                        </MKBox>
                    </>)}
                    <Divider sx={{mt: -2}}/>

                    {!loading && registration && <MKBox p={2}>
                        <MKTypography variant="body2">Create New ID</MKTypography>

                        <IdentificationForm phone={phone}
                                            name={name}
                                            handleChange={handleParticipantChange}
                        />
                    </MKBox>}
                    <Divider/>
                    {loading && <CircularProgress/>}

                    <Divider sx={{my: 0}}/>
                    {!loading && registration &&

                        <MKBox display="flex" justifyContent="space-between" p={1.5}>
                            <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                                close
                            </MKButton>
                            <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                                Save / Hifadhi
                            </MKButton>
                        </MKBox>}
                </MKBox>
            </div>
        </Slide>
    </Modal>)

};

export default IdentificationsModal;