/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

function DashboardCounters({dashbaord, setCurrentTab, setCurrentReportType}) {

    const handleClick = (e) => {
        const id = e.currentTarget.getAttribute('id');
        setCurrentTab('reports');
        setCurrentReportType(id)
    }
    return (
        <MKBox component="section" py={0}>
            <Container>
                <Grid container item xs={12} lg={12} sx={{mx: "auto"}}>
                    {dashbaord && dashbaord.map((item, index) =>
                        <Grid id={item.id} onClick={handleClick} item xs={12} md={item.size} display="flex">
                            <Divider orientation="vertical" sx={{display: {xs: "none", md: "block"}, ml: 0}}/>
                            <DefaultCounterCard
                                description={item.description}
                                color={item.color}
                                count={item.count}
                                suffix={item.suffix}
                                title={item.category}
                            />
                        </Grid>
                    )
                    }
                </Grid>
            </Container>
        </MKBox>
    );
}

export default DashboardCounters;
