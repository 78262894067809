/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons
// Material Kit 2 React components

// Material Kit 2 React example components

// Material Kit 2 React page layout routes
// Images
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import Swal from "sweetalert2";
import {CardContent, TextField} from "@mui/material";
import Divider from "@mui/material/Divider";

function LoginForm({email,
                       password,
                       handleChange
                   }) {

    return (
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <TextField sx={{mt: 1}}
                               label="Email/Barua pepe"
                               name="email"
                               type='email'
                               value={email}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Password/ Nywila"
                               name="password"
                               type='password'
                               value={password}
                               onChange={handleChange}
                               fullWidth
                    />

                </Grid>

            </Grid>
            <Divider/>

        </CardContent>
    );
}

export default LoginForm;
