import React, {useEffect, useState} from "react";
import Axios from "axios";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {CircularProgress} from "@mui/material";
import {v4 as uuidv4} from "uuid";
import {Divider} from "antd";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import appLogo from "../../assets/images/logo/tz.png";


// Material Kit 2 React examples
// Presentation page sections
import Information from "pages/Presentation/sections/Information";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/excavetor.jpg";
// import bgImage from "assets/images/header-img.jpeg";
// import bgImage from "assets/images/tanzania.jpg";
// Custom imports
import {Config} from "utils/Config";
import {GetProfile, GetTokenHeaders} from "common/ReusableFunction";
import AlertMessages from "utils/AlertMessage";
import RegistrationModal from "../expo/RegistrationModal";
import {Link} from "react-router-dom";
import Header from "./header";

function Presentation() {
    const [open, setOpen] = useState(false);
    const [isLogged, setIsLogged] = useState(false);
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const [formattedDate1, setFormattedDate1] = useState('');
    const [formattedDate2, setFormattedDate2] = useState('');
    const [formattedDate3, setFormattedDate3] = useState('');
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const apiURL = Config.apiURL;
    const headers = GetTokenHeaders();
    const profile = GetProfile();

    localStorage.removeItem("isAdmin");

    const getEvents = async () => {
        setLoading(true);
        try {
            const res = await Axios.get(apiURL + 'get-current-event', headers);
            if (res.status === 200) {
                const {status, message, events} = res.data.data;
                if (status === 100) {
                    setEvents(events);
                } else {
                    AlertMessages(status, message);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const getPostSession = async (payLoad) => {
        setLoading(true);
        try {
            const res = await Axios.post(apiURL + 'store-session', payLoad);
            const {status, message} = res.data.data;
            // AlertMessages(status, message);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        if (events) {
            const startDate = new Date(events.start_date);
            setFormattedDate1(startDate.toLocaleDateString('en-US', {day: 'numeric'}));

            const endDate = new Date(events.end_date);
            setFormattedDate2(endDate.toLocaleDateString('en-US', {day: 'numeric'}));
            setFormattedDate3(endDate.toLocaleDateString('en-US', {month: 'short', year: 'numeric'}));
        }
    }, [events]);

    useEffect(() => {
        const intervalID = setTimeout(() => {
            const isLoggedIn = localStorage.getItem("isAdmin") === 'true';
            setIsLogged(isLoggedIn);

            const updatedRoutes = routes.filter(route => !route.requireLogin || isLoggedIn);
            setFilteredRoutes(updatedRoutes);
        }, 5000);

        return () => clearTimeout(intervalID);
    }, [isLogged]);

    useEffect(() => {
        const sessionId = localStorage.getItem("mySessionId") || uuidv4();
        if (!localStorage.getItem("mySessionId")) {
            localStorage.setItem("mySessionId", sessionId);
        }

        const payLoad = {
            userAgent: navigator.userAgent,
            sessionId,
            action: localStorage.getItem("mySessionId") ? 'update-session' : 'new-session',
        };

        getPostSession(payLoad);
    }, []);

    return (
        <div className="bg-gray-200">
            {/* Fixed div at the top */}
            <Header/>

            {/* Adjusted content to not overlap with fixed div */}
            <div className="pt-6">
                {/*<DefaultNavbar*/}
                {/*    routes={filteredRoutes}*/}
                {/*    action={{*/}
                {/*        type: "internal",*/}
                {/*        route: "/login",*/}
                {/*        label: "Login",*/}
                {/*        color: "dark",*/}
                {/*    }}*/}
                {/*    sticky*/}
                {/*/>*/}
                <MKBox
                    minHeight="95vh"
                    width="100%"
                    sx={{
                        backgroundImage: `url(${bgImage})`,
                        backgroundSize: "cover",
                        backgroundPosition: "top",
                        display: "grid",
                        placeItems: "center",
                    }}
                >
                    <Container>
                        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                            {loading && <CircularProgress/>}
                            {!loading && events &&
                                <>
                                    <div
                                        className="-mt-24 rounded-lg text-center  px-2 py-2 text-4xl text-[#fb8c00] opacity-80 mb-1 font-bold bg-gray-50">
                                        {`${events.title}`}
                                    </div>
                                    {/*<div*/}
                                    {/*    className="-mt-24 rounded-lg text-center  px-2 py-2 text-4xl text-white opacity-80 mb-1 font-bold ">*/}
                                    {/*    {`${events.title}`}*/}
                                    {/*</div>*/}
                                    {/*<div*/}
                                    {/*    className="-mt-24 rounded-lg text-center  px-2 py-2 text-4xl text-white opacity-80 mb-1 font-bold bg-gray-50">*/}
                                    {/*    {`${events.title}`}*/}
                                    {/*</div>*/}


                                    <div
                                        className="rounded-lg text-center bg-gray-50 mb-2  px-4 py-2 text-xl text-[#fb8c00] font-bold">
                                        {`Kuanzia tarehe ${formattedDate1} hadi ${formattedDate2} ${formattedDate3}`}
                                    </div>


                                    {/*<MKButton size='lg' onClick={handleOpen} color="warning" sx={{color: dark}}>*/}
                                    {/*    <MKTypography*/}
                                    {/*        variant="h4"*/}
                                    {/*        color="inherit"*/}
                                    {/*        textAlign="center"*/}
                                    {/*        px={{xs: 6}}*/}
                                    {/*        mt={0}*/}
                                    {/*    >*/}
                                    {/*        Jisajili kushiriki / Register*/}
                                    {/*    </MKTypography>*/}
                                    {/*</MKButton>*/}
                                </>
                            }
                        </Grid>
                    </Container>
                </MKBox>
                <div className="-mt-16 ml-10 mr-10">
                    <Information/>
                </div>
                <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                    {open && <RegistrationModal open={open} handleClose={handleClose} events={events}/>}
                </div>
            </div>
        </div>
    );
}

export default Presentation;
