/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// ImageSlider Us page sections
// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
import SearchForm from "./SearchForm";
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Swal from "sweetalert2";
import Axios from "axios";
import {Link} from "react-router-dom";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress, IconButton, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {
    AddAlertOutlined,
    CancelOutlined, CreditScoreRounded,
    HomeOutlined,
    MenuBookOutlined,
    PlayArrow,
    ReceiptLongOutlined
} from "@mui/icons-material";
import BusinessTypesModal from "./BusinessTypesModal";
import PaymentApprovalModal from "./PaymentApprovalModal";
import CompletedRegistrationSummaryModal from "./CompletedRegistrationSummaryModal";

function PendingApprovals() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);

    const [open, setOpen] = useState(false);
    const [openApproval, setOpenApproval] = useState(false);
    const [documentLinks, setDocumentLinks] = useState([]);
    const [links, setLinks] = useState('');
    const [summary, setSummary] = useState();
    const [openSummary, setOpenSummary] = useState(false);


    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpenApproval = (e) => {
        const id = e.currentTarget.getAttribute('id');

        const typeRow = businessTypes[id];
        console.log({id:typeRow})
        const linkData = {
            registration_form : `${links}${typeRow.id}`,
            payment_form: typeRow.payment_receipt,
            applicationId: typeRow.id
        }
        setDocumentLinks(linkData);
        setOpen(false);
        setOpenApproval(true);
    };

    useEffect(() => {
        if (open) {
            setDocumentLinks(documentLinks);
        }
    }, [handleOpenApproval]);

    const handleClose = () => {
        setOpen(false);
        setOpenApproval(false);
        getApprovals().then(r => false)
    };

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getApprovals = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-pending-approvals',headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events);
                setLinks(res.data.data.link)
                setSummary(res.data.data.summary)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getApprovals().then(r => false);
    }, []);

    useEffect(() => {
        setBusinessTypes(businessTypes);
    }, [businessTypes]);
    useEffect(() => {
        setDocumentLinks(documentLinks);
    }, [documentLinks]);
    useEffect(() => {
        setLinks(links);
    }, [links]);


    const handleOpenSummary = () => {
        setOpenSummary(true);
    };
    const handleCloseSummary = () => {
        setOpenSummary(false);
    };
    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {

        }
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {

        }
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    }

    return (
        <>
            <div sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                &nbsp;
                <MKTypography sx={{ mb: 1, textAlign: 'right !important',mr:9 }} color='secondary' variant="body2">
                    Count: <b>{sumCounts()}</b> &nbsp; | &nbsp; Total Amount: <b>{formatedNumber(sumAmounts())} TZS</b> &nbsp; | &nbsp;
                    <MKButton onClick={handleOpenSummary} color='secondary'>View Summary</MKButton>
                </MKTypography>
            </div>

                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={12}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <Divider/>

                        {loading && <CircularProgress/>}
                        {businessTypes && Number(businessTypes.length) > 0 &&
                            <Container sx={{mt:-5}}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    lg={12}
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{mx: "auto", textAlign: "center"}}
                                >
                                    <Divider/>

                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <MKBox color='dark' component="thead">
                                                <TableRow>
                                                    <TableCell>
                                                        <b>SN</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>NAME</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>BUSINESS TYPE</b>
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>REFERENCE</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>PRICE</b>
                                                    </TableCell>
                                                    <TableCell align="center">

                                                    </TableCell>
                                                </TableRow>
                                            </MKBox>
                                            <MKBox color='text' component="tbody">
                                                {businessTypes && businessTypes.map((type, index) =>
                                                    <TableRow color='dark' key={type.id}>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='secondary' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {index + 1}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.name}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.type}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.classification}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.reference_no}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell id={index}>
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0,float:'right'}}>
                                                                {Number(type.current_price).toLocaleString()}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: -1, mt: -1, fontSize: 14}}>
                                                                <IconButton
                                                                    disabled={loading}
                                                                    color="dark"
                                                                    aria-label="Action Button"
                                                                    id={index}
                                                                    onClick={handleOpenApproval}
                                                                >
                                                                    {loading ? (
                                                                        <CircularProgress size={12} color="primary"/>
                                                                    ) : (
                                                                        <ReceiptLongOutlined color='warning'/>
                                                                    )}

                                                                </IconButton>
                                                            </MKTypography>
                                                        </TableCell>

                                                    </TableRow>
                                                )
                                                }

                                            </MKBox>
                                        </Table>
                                    </TableContainer>

                                </Grid>
                            </Container>
                        }
                    </Grid>
                    <BusinessTypesModal open={open} handleClose={handleClose}/>
                    <PaymentApprovalModal open={openApproval} handleClose={handleClose} documentLinks={documentLinks}/>
                    <CompletedRegistrationSummaryModal summary={summary} open={openSummary} handleClose={handleCloseSummary}/>

                </Container>


        </>
    );
}

export default PendingApprovals;
