import React, {useEffect, useState} from 'react';
import numeral from "numeral";
import Divider from "@mui/material/Divider";
import MKTypography from "../../components/MKTypography";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";
import MKButton from "../../components/MKButton";

const ProductList = ({setProducts, handleOpenOtherExpenses, setOtherExpensesDetails, otherExpenseAmount}) => {
    const [applicants, setApplicants] = useState([]);
    const [remainingAmount, setRemainingAmount] = useState(Number(otherExpenseAmount));
    const [selectedApplicantId, setSelectedApplicantId] = useState('');
    const [amount, setAmount] = useState(0);
    const [selectedStaff, setSelectedStaff] = useState('');

    const token = localStorage.getItem("token");
    const pfno = localStorage.getItem("pfno");
    let URL = 'https://imprest-api.nssf.go.tz/';
    const currentRole = localStorage.getItem("currentRole");

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform necessary validations and handle form submission
        const formValues = {
            applicants: applicants.map((applicant) => ({
                id: applicant.id, amount: Number(removeCommas(applicant.amount)),
            })),
        };
        setOtherExpensesDetails(formValues);
        handleOpenOtherExpenses();
    };
    useEffect(() => {
        const substring = selectedApplicantId.slice(0, 50);
        setSelectedApplicantId(substring);
        setAmount(amount);
        setSelectedStaff(selectedStaff);
        setRemainingAmount(remainingAmount);
    });

    const handleAddApplicant = async () => {
        if (!selectedApplicantId) {
            // Display an error message or take appropriate action
            console.log('No applicant selected');
            return;
        }

        if (selectedApplicantId && selectedApplicantId.length >= 3) {
            try {

                const duplicateApplicant = applicants.find((applicant) => applicant.id === selectedApplicantId);

                if (duplicateApplicant) {
                    // Display an error message or take appropriate action
                    console.log('Applicant already selected');
                    return;
                }

                // Update the state to add a new applicant
                const newApplicant = {id: selectedApplicantId};
                setApplicants([...applicants, newApplicant]);
                setProducts([...applicants, newApplicant]);
                setSelectedApplicantId('');
                setAmount(0);

            } catch (error) {
                setSelectedStaff('');
            }
        } else {
            setSelectedStaff('');
            alert('Enter valid business')
        }


    };

    useEffect(() => {

    }, [setSelectedStaff]);

    const handleAmountChange = (index, newAmount) => {
        // Perform necessary validations and update the state with the new amount
        const updatedApplicants = [...applicants];
        updatedApplicants[index].amount = newAmount;
        setApplicants(updatedApplicants);
    };

    function cancelForms() {
        setApplicants([]);
        setAmount(0);
        setSelectedApplicantId('');
        setRemainingAmount(Number(otherExpenseAmount));
    }

    const formatNumber = (value) => {
        return numeral(value).format('0,0');
    };

    function removeCommas(value) {
        return value.toString().replace(/,/g, '');
    }

    // Render the dynamic form
    return (<form onSubmit={handleSubmit}>
        <div className='row'>

            <Grid container spacing={2}>

                <Grid item xs={12}>
                    <div className="mb-3 col-md-12">
                        <MKTypography variant="body2">List of products/Orodha ya bidhaa za maonesho</MKTypography>
                    </div>
                    <Divider sx={{mb: -2, mt: 1}}/>
                </Grid>
                <Grid item xs={10}>
                    <TextField sx={{mt: 1}}
                               label="Enter Product/ Ingiza aina ya bidhaa (Max: 50 characters)"
                               name="product"
                               value={selectedApplicantId}
                               fullWidth
                               onInput={(e) => {
                                   setSelectedApplicantId(e.target.value);
                               }}
                    />

                </Grid>
                <Grid item xs={2}>
                    <MKButton sx={{ml: -1, mt: 1}} onClick={handleAddApplicant} variant="gradient"
                              color="secondary">
                        Add
                    </MKButton>
                </Grid>
            </Grid>

        </div>
        <Divider sx={{mb: 0}}/>
        {applicants.map((applicant, index) => (<div key={index}>
            <MKTypography variant="body2">{`${index + 1}: ${applicant.id}`}</MKTypography>
        </div>))}
        <div className='row'>

            {applicants && Number(applicants.length) > 0 && <div className="mb-3 col-md-12 " style={{display: 'flex', justifyContent: 'flex-end'}}>
                <MKButton size='small' sx={{ml: -1, mt: 1}} onClick={cancelForms} variant="gradient" color="error">
                    Reset
                </MKButton>

            </div>}
                <div className="mb-3 col-md-6">
            {Number(remainingAmount) === 0 && <button type="submit" className="btn btn-success"
                >Submit
                </button>}
        </div>
    </div>
</form>

)
};

export default ProductList;