import React, {useEffect, useState} from 'react';
import {Divider, Modal} from 'antd';

function ControlNoFormModal(props) {

    const [selectedCategory, setSelectedCategory] = useState([]);

    const [formData, setFormData] = useState({
        id: props.participant ? props.participant.id : '',
        name: props.participant ? props.participant.partcipant_name : '',
        reference_no: props.participant ? props.participant.reference_no : '',
        phone: props.participant ? props.participant.phone : '',
        amount: props.participant ? props.participant.amount : '',
        business_type: props.participant ? props.participant.business_type : '',
    });

    const handleChange = (e) => {
        const {name, value} = e.target;
        // Handle other form field changes if needed
        setFormData((prevData) => ({...prevData, [name]: value}));

    };

    useEffect(() => {
        setSelectedCategory(selectedCategory);
    });

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior

        // Submit the form data
        props.onSubmit(formData);
        props.closeModal(); // Close the modal after submission
    };

    const categoryOptions = props.categories;

    return (
        <Modal
            title={'Issue Control Number'}
            open={props.isOpen}
            onCancel={props.closeModal}
            width={'60%'}
            style={{
                top: 20,
            }}
            footer={<></>}
        >
            <Divider/>
            <form onSubmit={handleSubmit}>
                <div className="modal-container grid grid-cols-2 gap-4">

                    <div className="mb-1">
                        <label className="label">
                            Name
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    <div className="mb-1">
                        <label className="label">
                            Reference No
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="reference_no"
                            value={formData.reference_no}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>

                    <div className="mb-1">
                        <label className="label">
                            Phone
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-1">
                        <label className="label">
                            Business Type
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="business_type"
                            value={formData.business_type}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>


                    <div className="mb-1">
                        <label className="label">
                            Amount
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            required
                            readOnly
                            disabled
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>


                    <div className="mb-1">
                        <label className="label">
                            Control No
                            <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="control_no"
                            value={formData.control_no}
                            onChange={handleChange}
                            required
                            className="w-full border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:border-blue-500"
                        />
                    </div>


                    {/* Add more fields individually as needed */}
                </div>
                <div className="modal-footer flex justify-end mt-3">
                    <button
                        type="submit"
                        className="bg-[#365e7d] border-0 text-xs text-white px-3 py-2 rounded-md"
                    >
                        {'Save Control No'}
                    </button>
                </div>
            </form>
        </Modal>
    );
}

// Properties
ControlNoFormModal.defaultProps = {
    isOpen: false,
    closeModal: function () {
    },
    onSubmit: (formData) => {
    },
};

export default ControlNoFormModal;
