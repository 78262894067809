import React from 'react';
import {Card, CardContent, CircularProgress, InputLabel, Select, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProductList from "./ProductList";
import MenuItem from "@mui/material/MenuItem";
import InputMask from 'react-input-mask';

const IdentificationForm = ({name, phone,handleChange}) => {
    const categoryOptions = ['Cash','Inkind'];
    return (<Card>
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <TextField sx={{mt: 1}}
                               label="Name/Jina"
                               name="name"
                               value={name}
                               onChange={handleChange}
                               fullWidth
                    />
                    <InputMask
                        mask="255 999 999 999"
                        value={phone}
                        onChange={handleChange}
                    >
                        {() => (<TextField
                            label="Phone/Simu"
                            name="phone"
                            fullWidth
                            sx={{mt:1}}
                        />)}
                    </InputMask>

                </Grid>


            </Grid>

        </CardContent>
    </Card>);
};

export default IdentificationForm;