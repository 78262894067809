import React from 'react';
import {Card, CardContent, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import InputMask from "react-input-mask";

const UserRegistrationForm = ({
                                  first_name,
                                  middle_name,
                                  last_name,
                                  email,
                                  phone,
                                  password,
                                  confirm_password,
                                  handleChange
                              }) => {

    return (<Card>
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <TextField sx={{mt: 1}}
                               label="First Name/ Jina la kwanza"
                               name="first_name"
                               value={first_name}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Middle Name/ Jina la kati"
                               name="middle_name"
                               value={middle_name}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Last name/ Jina la mwisho"
                               name="last_name"
                               value={last_name}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Email/Barua pepe"
                               name="email"
                               type='email'
                               value={email}
                               onChange={handleChange}
                               fullWidth
                    />
                    <InputMask
                        mask="255 999 999 999"
                        value={phone}
                        onChange={handleChange}
                    >
                        {() => (
                            <TextField sx={{mt: 1}}
                                label="Phone Number/Namba ya Simu"
                                name="phone"
                                fullWidth
                            />
                        )}
                    </InputMask>
                    <TextField sx={{mt: 1}}
                               label="Password/ Nywila"
                               name="password"
                               type='password'
                               value={password}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 1}}
                               label="Confirm Password/ Thibitisha Nywila"
                               name="confirm_password"
                               type='password'
                               value={confirm_password}
                               onChange={handleChange}
                               fullWidth
                    />
                </Grid>

            </Grid>
            <Divider/>

        </CardContent>
    </Card>);
};

export default UserRegistrationForm;