import React from "react";

import {Divider} from "antd";

import {Link} from "react-router-dom";
import appLogo from "../../assets/images/logo/tz.png";


function Footer() {


    return (

        <div className="fixed bottom-0 left-0 w-full bg-white rounded-t-xl p-2 z-50 border-gray-200 border-1">
            <div className="grid grid-cols-10">

                <div className="col-span-10 text-center  text-[#fb8c00] font-bold text-sm">
                    RAS Geita &copy; {new Date().getFullYear()}
                </div>

            </div>
        </div>


    );
}

export default Footer;
