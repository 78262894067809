/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import {GetIsAdmin} from "./common/ReusableFunction";
import routesAdmin from "./routesAdmin";

import './index.css'; // Import Tailwind CSS
import 'antd/dist/antd.js';
import Registration from "./pages/Presentation/registration";
import RegistrationManagement from "./pages/Presentation/registrationManagement";

export default function App() {
    const {pathname} = useLocation();

    let isAdmin = GetIsAdmin();

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    useEffect(() => {
        isAdmin = GetIsAdmin();
    },);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }

            return null;
        });


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Routes>
                {isAdmin && isAdmin === 'true' ? getRoutes(routesAdmin) : getRoutes(routes)}
                <Route path="/presentation" element={<Presentation/>}/>
                <Route path="/registration" element={<Registration/>}/>
                <Route path="/registration-management/:id" element={<RegistrationManagement/>}/>
                <Route path="*" element={<Navigate to="/presentation"/>}/>
            </Routes>
        </ThemeProvider>
    );
}
