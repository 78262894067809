import React, {useEffect, useState} from 'react';
import {List} from '@mui/material';
import {Modal} from 'antd';
import ParticipantForm from './ParticipantForm';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import Swal from "sweetalert2";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";

const RegistrationModal = ({open, handleClose, events}) => {
    const [tin, setTin] = useState('');
    const [seasonId, setSeasonId] = useState('GT2023');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [products, setProducts] = useState([]);
    const [category, setCategory] = useState('');
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);
    let [businessTypes, setBusinessTypes] = useState([]);
    let [businessTypesDetails, setBusinessTypesDetails] = useState('');
    let [mjasiliamaliCategories, setMjasiliamaliCategories] = useState('');

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getTypes = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-business-type-list', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events)
            }

            setLoading(false)
        }
    }
    const getTypeDetails = async (category) => {
        setLoading(true)
        const payLoad = {
            category: category
        }
        const res = await Axios.post(apiURL + 'get-business-type-details', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypesDetails(res.data.data.events);
                setMjasiliamaliCategories(res.data.data.categories);
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getTypes().then(r => false);
    }, []);
    useEffect(() => {
        setBusinessTypes(businessTypes);
    }, [businessTypes]);

    useEffect(() => {
        setBusinessTypesDetails(businessTypesDetails);
        setMjasiliamaliCategories(mjasiliamaliCategories)
    }, [businessTypesDetails]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        setSelectedOption(selectedOption);
    }, [selectedOption]);


    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'tin') {
            setTin(value);
        } else if (name === 'seasonId') {
            setSeasonId(value);
        } else if (name === 'name') {
            setName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'phone') {
            setPhone(value);
        } else if (name === 'address') {
            setAddress(value);
        } else if (name === 'category') {
            setCategory(value);
            getTypeDetails(value).then(r => false);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        let sessionId = localStorage.getItem("mySessionId");
        const payLoad = {
            tin: tin,
            seasonId: events,
            name: name,
            email: email,
            phone: phone,
            address: address,
            products: products,
            category: category,
            classification: selectedOption,
            session_id: sessionId
        }
        console.log(payLoad);
        registerMember(payLoad).then(r => false);

        // Reset form fields
        setTin('');
        setSeasonId('');
        setName('');
        setEmail('');
        setPhone('');
        // Close the modal
    };

    const registerMember = async (payLoad) => {
        setLoading(true)
        setErrors([]);

        if (payLoad.category === '2: Mjasiriamali') {
            if (payLoad.classification === null || payLoad.classification === 'undefined' || payLoad.classification === '') {
                handleClose();
                return AlertMessages(103, 'Tafadhali chagua msajili wa mjasiriamali');
            }
        }
        const res = await Axios.post(apiURL + 'create-participant', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                const id = res.data.data.id;
                handleClose();
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger'
                    }, buttonsStyling: true
                })

                swalWithBootstrapButtons.fire({
                    title: 'Success', text: message, icon: 'success', showCancelButton: false, confirmButtonText: 'Ok',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        window.open(`https://madinigeita.co.tz/maonesho-api/public/api/participant-details/${id}`, '_blank');
                    }
                })

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }

    return (
        // <Modal
        //     open={open}
        //     onClose={handleClose}
        //     sx={{display: "grid", placeItems: "center"}}
        // >
            <Modal
                title={'Exhibitor registration / Usajili wa mshiriki wa maonesho'}
                open={open}
                onCancel={handleClose}
                onClose={handleClose}
                width={'55%'}
                style={{
                    top: 20,
                }}
                footer={<></>}
            >
                <Divider/>
        <Slide direction="down" in={open} timeout={500}>
                <MKBox
                    position="relative"
                    width="650px"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >

                    {errors && errors.map((record, index) => <List>
                        <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                      fontWeight="light">
                            <b>{record.label}:&nbsp;</b>{record.value}<br/>
                        </MKTypography>
                    </List>)}
                    <MKBox p={2}>
                        <ParticipantForm
                            tin={tin}
                            seasonId={seasonId}
                            name={name}
                            email={email}
                            phone={phone}
                            address={address}
                            category={category}
                            setProducts={setProducts}
                            handleChange={handleParticipantChange}
                            categoryOptions={businessTypes}
                            isLoading={loading}
                            typeDetails={businessTypesDetails}
                            handleOptionChange={handleOptionChange}
                            categories={mjasiliamaliCategories}
                            selectedOption={selectedOption}
                        />
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                            close
                        </MKButton>
                        <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                            Save / Kamilisha usajili
                        </MKButton>
                    </MKBox>
                </MKBox>
        </Slide>
    </Modal>);
};

export default RegistrationModal;