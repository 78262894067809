/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// ImageSlider Us page sections
// Routes
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
import SearchForm from "./SearchForm";
import React, {useEffect, useState} from "react";
import {Config} from "../../utils/Config";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import AlertMessages from "../../utils/AlertMessage";
import Swal from "sweetalert2";
import Axios from "axios";
import {Link} from "react-router-dom";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import {CircularProgress, IconButton, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {AddAlertOutlined, HomeOutlined, PlayArrow} from "@mui/icons-material";
import BusinessTypesModal from "./BusinessTypesModal";

function BusinessTypes() {
    let [loading, setLoading] = useState(false);
    let [businessTypes, setBusinessTypes] = useState([]);

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        getTypes().then(r => false)
    };

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getTypes = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-business-types',headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getTypes().then(r => false);
    }, []);

    useEffect(() => {
        setBusinessTypes(businessTypes);
    }, [businessTypes]);


    return (
        <>


                    <Grid
                        container
                        item
                        xs={12}
                        lg={12}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{mx: "auto", textAlign: "center"}}
                    >
                        <Container span={12}>
                            <MKButton color='warning' type='default' onClick={handleOpen}
                                    style={{float: 'right'}}><AddAlertOutlined/>&nbsp; New
                                Type</MKButton>

                        </Container>
                        {loading && <CircularProgress/>}
                        {businessTypes && Number(businessTypes.length) > 0 &&
                            <Container>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    lg={12}
                                    justifyContent="center"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{mx: "auto", textAlign: "center"}}
                                >
                                    <Divider/>

                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <MKBox color='dark' component="thead">
                                                <TableRow>
                                                    <TableCell>
                                                        <b>SN</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>NAME</b>
                                                    </TableCell>
                                                    <TableCell>
                                                        <b>DESCRIPTION</b>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <b>CURRENT PRICE</b>
                                                    </TableCell>

                                                </TableRow>
                                            </MKBox>
                                            <MKBox color='text' component="tbody">
                                                {businessTypes && businessTypes.map((type, index) =>
                                                    <TableRow color='dark' key={type.id}>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='secondary' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {index + 1}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='info' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.name}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.description}
                                                            </MKTypography>
                                                        </TableCell>
                                                        <TableCell id={index}>
                                                            <MKTypography color='dark' variant="body2"
                                                                          fontWeight="small"
                                                                          textTransform="capitalize"
                                                                          sx={{mb: 0, mt: 0}}>
                                                                {type.current_price}
                                                            </MKTypography>
                                                        </TableCell>


                                                    </TableRow>
                                                )
                                                }

                                            </MKBox>
                                        </Table>
                                    </TableContainer>

                                </Grid>
                            </Container>
                        }
                    </Grid>
                    <BusinessTypesModal open={open} handleClose={handleClose}/>


        </>
    );
}

export default BusinessTypes;
