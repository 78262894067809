/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// Material Kit 2 React examples
import MKTypography from "../../components/MKTypography";
import React from "react";
import MKButton from "../../components/MKButton";

function ReportDetails({report, downloadReport, reportType}) {

    return (
        <Container>
            <Grid sx={{mx: 'auto'}} container item xs={12} lg={9}>
                <Grid item xs={12} md={12}>

                    {report && reportType === 'pdf' &&
                        <iframe
                            src={`data:application/pdf;base64,${report}`}
                            title="Report Document"
                            width="100%"
                            height="600"
                        ></iframe>
                    }
                    {report && reportType === 'excel' &&
                        <iframe
                            src={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${report}`}
                            title="Report Document"
                            width="100%"
                            height="600"
                        ></iframe>
                    }
                    {reportType === '' && <MKTypography sx={{mt: 2}} variant='body2' color='error'>
                        Nothing to show
                    </MKTypography>}
                </Grid>
                <Grid item xs={12} md={12}>
                    {report && reportType === 'pdf' && <MKButton onClick={downloadReport} color='dark'>
                        Download Excel
                    </MKButton>}
                </Grid>
            </Grid>

        </Container>
    );
}

export default ReportDetails;
