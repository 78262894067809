/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// ImageSlider Us page sections
// Routes
// Images
import React, {useEffect, useState} from "react";
import MKButton from "../../components/MKButton";
import Divider from "@mui/material/Divider";
import {CircularProgress, Table, TableCell, TableContainer, TableRow} from "@mui/material";
import {CreditScoreRounded} from "@mui/icons-material";
import AlertMessages from "../../utils/AlertMessage";
import {Config} from "../../utils/Config";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import Axios from "axios";
import {useNavigate} from "react-router-dom";
import SponsorModal from "./SponsorModal";
import CompletedRegistrationSummaryModal from "./CompletedRegistrationSummaryModal";

function Sponsors() {
    let [sponsors, setSponsors] = useState([]);
    let [summary, setSummary] = useState([]);
    let [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openSummary, setOpenSummary] = useState(false);
    let [currentEvent, setCurrentEvent] = useState([]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenSummary = () => {
        setOpenSummary(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseSummary = () => {
        setOpenSummary(false);
    };

    const history = useNavigate();

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    const getCurrentEvent = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-current-event', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setCurrentEvent(res.data.data.events)
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getCurrentEvent().then(r => false);
    }, []);

    const getSponsors = async () => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-sponsors', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setSponsors(res.data.data.sponsors)
                setSummary(res.data.data.summary)
            }
            setLoading(false)
        }
    }


    useEffect(() => {
        getSponsors().then(r => false);
    }, []);

    useEffect(() => {
        setSponsors(sponsors);
        setSummary(summary)
    }, [sponsors]);

    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {

        }
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {

        }
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    }
    return (
        <div className="px-2">

            <div className="px-2  py-2 bg-white rounded-lg mb-2" sx={{display: 'flex', justifyContent: 'flex-end'}}>
                &nbsp;
                <MKTypography sx={{mb: 1, textAlign: 'right !important', mr: 9}} color='secondary' variant="body2">
                    Count: <b>{sumCounts()}</b> &nbsp; | &nbsp; Total
                    Amount: <b>{formatedNumber(sumAmounts())} TZS</b> &nbsp; | &nbsp;
                    <MKButton onClick={handleOpenSummary} color='secondary'>View Summary</MKButton> |&nbsp; <MKButton
                    color='warning' type='default' onClick={handleOpen}
                    style={{float: 'right'}}><CreditScoreRounded/>&nbsp; New
                    Sponsor</MKButton>
                </MKTypography>
            </div>

            {loading && <CircularProgress/>}
            {sponsors && Number(sponsors.length) > 0 &&
                <TableContainer>
                    <Table aria-label="simple table">
                        <MKBox color='dark' component="thead">
                            <TableRow>
                                <TableCell>
                                    <div className="text-sm font-bold">SN</div>
                                </TableCell>
                                <TableCell>
                                    <b>
                                        <div className="text-sm font-bold">NAME</div>
                                    </b>
                                </TableCell>
                                <TableCell>
                                    <div className="text-sm font-bold">ADDRESS</div>
                                </TableCell>
                                <TableCell>
                                    <div className="text-sm font-bold">PHONE</div>
                                </TableCell>
                                <TableCell align="center">
                                    <div className="text-sm font-bold">EMAIL</div>
                                </TableCell>

                                <TableCell align="center">
                                    <div className="text-sm font-bold">MODE</div>
                                </TableCell>
                                <TableCell align="center">
                                    <div className="text-sm font-bold">AMOUNT</div>
                                </TableCell>

                            </TableRow>
                        </MKBox>
                        <MKBox color='text' component="tbody">
                            {sponsors && sponsors.map((event, index) =>
                                <TableRow color='dark' key={event.id}>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='secondary' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{index + 1}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='info' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{event.name}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{event.address}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell id={index}>
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm"> {event.phone}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{event.email}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{event.mode}</div>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <MKTypography color='dark' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0}}>
                                            <div className="text-sm">{Number(event.amount).toLocaleString()}</div>
                                        </MKTypography>
                                    </TableCell>


                                </TableRow>
                            )
                            }

                        </MKBox>
                    </Table>
                </TableContainer>
            }

            <SponsorModal open={open} handleClose={handleClose} getSponsors={getSponsors}/>
            <CompletedRegistrationSummaryModal summary={summary} open={openSummary} handleClose={handleCloseSummary}/>

        </div>
    );
}

export default Sponsors;
