import React from 'react';
import {CircularProgress, FormControlLabel, InputLabel,Select, Radio, RadioGroup, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProductList from "./ProductList";
import InputMask from 'react-input-mask';
import ReactHtmlParser from 'react-html-parser';
import MKTypography from "../../components/MKTypography";
import MenuItem from "@mui/material/MenuItem";


const ParticipantForm = ({
                             existing,
                             tin,
                             setProducts,
                             name,
                             email,
                             phone,
                             handleChange,
                             address,
                             category,
                             categoryOptions,
                             isLoading,
                             typeDetails,
                             handleOptionChange,
                             categories,
                             selectedOption
                         }) => {


    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>Select business Category/Chagua aina ya biashara</InputLabel>
                    {isLoading && <CircularProgress sx={{mt: 1, ml: 3}}/>}
                    {!isLoading &&
                        <Select fullWidth sx={{height: 40, mt: 1}}
                                name="category" value={category} onChange={handleChange}>
                            <MenuItem value="" disabled>
                                Select a category
                            </MenuItem>
                            {categoryOptions.map((option) => (<MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>))}
                        </Select>

                    }

                    {categories && category === '2: Mjasiriamali' && <RadioGroup
                        name="options"
                        value={selectedOption}
                        onChange={handleOptionChange}
                        row
                    >
                        {categories.map((rec) => (
                            <FormControlLabel
                                key={rec}
                                value={rec}
                                control={<Radio/>}
                                label={rec}
                            />
                        ))}
                    </RadioGroup>
                    }
                    {category &&
                        <div className="text-yellow-700 text-sm">
                            <Divider/>
                            {ReactHtmlParser(typeDetails)}
                            <Divider/>
                        </div>

                    }

                    {!existing.id &&
                        <>
                            <TextField sx={{mt: 1}}
                                       label={existing.id > 0 ? '' : "Name/Jina"}
                                       name="name"
                                       value={existing.id ? existing.name : name}
                                       onChange={handleChange}
                                       fullWidth
                                       disabled={existing.email}
                            />
                            <TextField sx={{mt: 1}}
                                       label={existing.address ? '' : "Address/Anuani ya biashara"}
                                       name="address"
                                       value={address}
                                       onChange={handleChange}
                                       fullWidth

                            />
                        </>
                    }
                    {!existing.id &&
                        <TextField sx={{mt: 1}}
                                   label={existing.email ? '' : "Email/Barua pepe"}
                                   name="email"
                                   value={existing.id ? existing.email : email}
                                   onChange={handleChange}
                                   fullWidth
                                   disabled={existing.id > 0}

                        />}
                </Grid>
                {!existing.id &&
                    <>
                        <Grid item xs={6}>
                            <InputMask
                                mask="999-999-999"
                                value={existing.id ? existing.tin : tin}
                                onChange={handleChange}
                                disabled={existing.id > 0}
                            >
                                {() => (<TextField
                                    label={existing.id > 0 ? '' : "TIN"}
                                    name="tin"
                                    fullWidth
                                />)}
                            </InputMask>

                        </Grid>
                        <Grid item xs={6}>

                            <InputMask
                                mask="255 999 999 999"
                                value={existing.id ? existing.phone : phone}
                                onChange={handleChange}
                                disabled={existing.id > 0}
                            >
                                {() => (<TextField
                                    label={existing.id > 0 ? '' : "Phone/Simu"}
                                    name="phone"
                                    fullWidth
                                />)}
                            </InputMask>
                        </Grid>
                    </>
                }
            </Grid>
            <Divider/>
            <Grid item xs={12}>
                <ProductList setProducts={setProducts}/>
            </Grid>
        </div>
    );
};

export default ParticipantForm;
