import React from 'react';
import {Card, CardContent, InputLabel, Select, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ProductList from "./ProductList";
import MenuItem from "@mui/material/MenuItem";
import InputMask from 'react-input-mask';

const BusinessTypeForm = ({name, description, price, handleChange}) => {
    const categoryOptions = ['Wachimbaji', 'Taasisi / Kampuni binafsi', 'Chakula vinywaji na migahawa', 'Eneo maalum la Michezo ya watoto', 'Mama Lishe', 'Wajasiriamali chini ya SIDO', 'Wajasiriamali chini ya MACHINGA', 'Wajasiriamali chini ya TWCC', 'Wajasiriamali chini ya TCCIA',];

    return (<Card>
        <CardContent>
            <Grid container spacing={2}>

                <Grid item xs={12}>

                    <TextField sx={{mt: 1}}
                               label="Business Type/Aina ya biashara"
                               name="name"
                               value={name}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField multiline minRows='2' sx={{mt: 1}}
                               label="Description/Maelezo"
                               name="description"
                               value={description}
                               onChange={handleChange}
                               fullWidth
                    />
                    <TextField sx={{mt: 2}}
                               label="Price/Bei"
                               name="price"
                               type='number'
                               value={price}
                               onChange={handleChange}
                               fullWidth
                    />
                </Grid>
            </Grid>

        </CardContent>
    </Card>);
};

export default BusinessTypeForm;