import React from 'react';
import {Modal, Table, TableCell, TableContainer, TableRow} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import Divider from "@mui/material/Divider";

const CompletedRegistrationSummaryModal = ({summary, open, handleClose}) => {
    const sumAmounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.amount), 0);
        } catch (e) {

        }
        return total;
    };
    const sumCounts = () => {
        let total = 0;
        try {
            total = summary.reduce((total, record) => total + parseFloat(record.count), 0);
        } catch (e) {

        }
        return total;
    };

    const formatedNumber = (number) => {
        return Number(number).toLocaleString('en-US', {
            style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2,
        });
    }
    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>
            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                <MKBox
                    position="relative"
                    width="650px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <Divider/>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <MKBox color='dark' component="thead">
                                <TableRow>
                                    <TableCell>
                                        <b>SN</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>BUSINESS TYPE</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>COUNT</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>AMOUNT</b>
                                    </TableCell>

                                </TableRow>
                            </MKBox>
                            <MKBox color='text' component="tbody">
                                {summary && summary.map((type, index) =>
                                    <TableRow color='dark' key={type.id}>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='secondary' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {index + 1}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0}}>
                                                {type.name}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <MKTypography color='dark' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0, textAlign:'center'}}>
                                                {type.count}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell id={index}>
                                            <MKTypography color='black' variant="body2"
                                                          fontWeight="small"
                                                          textTransform="capitalize"
                                                          sx={{mb: 0, mt: 0, float: 'right'}}>
                                                {Number(type.amount).toLocaleString()}
                                            </MKTypography>
                                        </TableCell>

                                    </TableRow>
                                )
                                }
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <MKTypography color='black' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0, float: 'right'}}>
                                            <b>Total</b>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell>
                                        <MKTypography color='black' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0, textAlign: 'center'}}>
                                            <b>{sumCounts()}</b>
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell>
                                        <MKTypography color='black' variant="body2"
                                                      fontWeight="small"
                                                      textTransform="capitalize"
                                                      sx={{mb: 0, mt: 0, float: 'right'}}>
                                            <b>{formatedNumber(sumAmounts())}</b>
                                        </MKTypography>
                                    </TableCell>

                                </TableRow>
                            </MKBox>
                        </Table>
                    </TableContainer>
                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default CompletedRegistrationSummaryModal;