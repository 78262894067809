import React, {useEffect, useState} from 'react';
import {List, Modal} from '@mui/material';
import Slide from "@mui/material/Slide";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MKButton from "../../components/MKButton";
import {Config} from "../../utils/Config";
import AlertMessages from "../../utils/AlertMessage";
import Axios from "axios";
import {GetProfile, GetTokenHeaders} from "../../common/ReusableFunction";
import BusinessTypeForm from "./BusinessTypeForm";

const RegistrationModal = ({open, handleClose}) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState();
    const [price, setPrice] = useState(0);
    let [loading, setLoading] = useState(false);
    let [errors, setErrors] = useState([]);

    let apiURL = Config.apiURL;
    let headers = GetTokenHeaders();
    const profile = GetProfile();

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'name') {
            setName(value);
        } else if (name === 'description') {
            setDescription(value);
        } else if (name === 'price') {
            setPrice(value);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        const payLoad = {
            name: name,
            description: description,
            current_price: price,
            created_by: profile.id,
        }
        console.log(payLoad);
        registerBusinessType(payLoad).then(r => false);

        // Reset form fields
        setName('');
        setDescription('');
        setPrice(0);
        // Close the modal
    };

    const registerBusinessType = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'create-business-type', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                const id = res.data.data.id;
                handleClose();
                AlertMessages(status,message)

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }

    return (<Modal open={open} onClose={handleClose} sx={{display: "grid", placeItems: "center"}}>
        <Slide direction="down" in={open} timeout={500}>
            <div sx={{maxHeight: '80vh', overflow: 'auto'}}>
                <MKBox
                    position="relative"
                    width="450px"
                    maxHeight="85vh"
                    overflow="auto"
                    display="flex"
                    flexDirection="column"
                    borderRadius="xl"
                    bgColor="white"
                    shadow="xl"
                >
                    <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                        <MKTypography variant="h5">Create New Business Type</MKTypography>
                        <CloseIcon fontSize="medium" sx={{cursor: "pointer"}} onClick={handleClose}/>
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    {errors && errors.map((record, index) => <List>
                        <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                      fontWeight="light">
                            <b>{record.label}:&nbsp;</b>{record.value}<br/>
                        </MKTypography>
                    </List>)}
                    <MKBox p={2}>
                        <BusinessTypeForm
                            name={name}
                            description={description}
                            price={price}
                            handleChange={handleParticipantChange}
                        />
                    </MKBox>
                    <Divider sx={{my: 0}}/>
                    <MKBox display="flex" justifyContent="space-between" p={1.5}>
                        <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                            close
                        </MKButton>
                        <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                            Save / Hifadhi
                        </MKButton>
                    </MKBox>
                </MKBox>
            </div>
        </Slide>
    </Modal>);
};

export default RegistrationModal;