import React, {useEffect, useState} from "react";
import Axios from "axios";
import {Divider} from "antd";

import {Config} from "utils/Config";
import {GetTokenHeaders} from "common/ReusableFunction";
import AlertMessages from "utils/AlertMessage";
import ImageSlider from "./imageSlider";
import {TextField} from "@mui/material";
import InputMask from "react-input-mask";
import ParticipantForm from "../expo/ParticipantForm";
import Swal from "sweetalert2";
import MKButton from "../../components/MKButton";
import MKBox from "../../components/MKBox";
import Header from "./header";
import {useNavigate} from 'react-router-dom';


function Registration() {
    const [open, setOpen] = useState(false);
    const [isLogged, setIsLogged] = useState(false);

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const apiURL = Config.apiURL;
    const [tin, setTin] = useState('');
    const [checkTin, setCheckTin] = useState('');
    const [checkPhone, setCheckPhone] = useState('');
    const [seasonId, setSeasonId] = useState('GT2023');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [products, setProducts] = useState([]);
    const [existingRegistration, setExistingRegistration] = useState([]);
    const [showRegistration, setShowRegistration] = useState(false);
    const [currentRegistration, setCurrentRegistration] = useState(false);
    const [category, setCategory] = useState('');
    let [errors, setErrors] = useState([]);
    let [businessTypes, setBusinessTypes] = useState([]);
    let [businessTypesDetails, setBusinessTypesDetails] = useState('');
    let [mjasiliamaliCategories, setMjasiliamaliCategories] = useState('');

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    let headers = GetTokenHeaders();

    const getTypes = async (payLoad) => {
        setLoading(true)
        const res = await Axios.get(apiURL + 'get-business-type-list', headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypes(res.data.data.events)
            }

            setLoading(false)
        }
    }
    const getTypeDetails = async (category) => {
        setLoading(true)
        const payLoad = {
            category: category
        }
        const res = await Axios.post(apiURL + 'get-business-type-details', payLoad, headers);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                setBusinessTypesDetails(res.data.data.events);
                setMjasiliamaliCategories(res.data.data.categories);
            }

            setLoading(false)
        }
    }
    useEffect(() => {
        getTypes().then(r => false);
    }, []);
    useEffect(() => {
        setBusinessTypes(businessTypes);
    }, [businessTypes]);

    useEffect(() => {
        setBusinessTypesDetails(businessTypesDetails);
        setMjasiliamaliCategories(mjasiliamaliCategories)
    }, [businessTypesDetails]);

    useEffect(() => {
        setErrors(errors);
    }, [errors]);

    useEffect(() => {
        setSelectedOption(selectedOption);
    }, [selectedOption]);


    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'tin') {
            setTin(value);
        } else if (name === 'seasonId') {
            setSeasonId(value);
        } else if (name === 'name') {
            setName(value);
        } else if (name === 'email') {
            setEmail(value);
        } else if (name === 'phone') {
            setPhone(value);
        } else if (name === 'address') {
            setAddress(value);
        } else if (name === 'category') {
            setCategory(value);
            getTypeDetails(value).then(r => false);
        }
    };

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');
        let sessionId = localStorage.getItem("mySessionId");
        const payLoad = {
            tin: existingRegistration.id ? existingRegistration.tin : tin,
            seasonId: events,
            name: existingRegistration.id ? existingRegistration.name : name,
            email: existingRegistration.id ? existingRegistration.email : email,
            phone: existingRegistration.id ? existingRegistration.phone : phone,
            address: existingRegistration.id ? existingRegistration.address : address,
            products: products,
            category: category,
            classification: selectedOption,
            session_id: sessionId
        }
        console.log(payLoad);
        registerMember(payLoad).then(r => false);

        // Reset form fields
        setTin('');
        setSeasonId('');
        setName('');
        setEmail('');
        setPhone('');
        // Close the modal
    };

    const navigate = useNavigate();

    const checkParticipantRegistration = async (payLoad) => {
        setLoading(true)
        setErrors([]);


        const res = await Axios.post(apiURL + 'check-participant-registration', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                setExistingRegistration(res.data.data.registration);
                setShowRegistration(false);
                setCurrentRegistration(true)
                navigate(`/registration-management/${res.data.data.registration.id}`);
            } else if (status === 102) {
                setExistingRegistration(res.data.data.registration);
                setShowRegistration(true);
                setCurrentRegistration(false)
                setTin(existingRegistration.tin);
                setName(existingRegistration.name);
                setEmail(existingRegistration.email);
                setPhone(existingRegistration.phone);
                setAddress(existingRegistration.address);
                setCategory(existingRegistration.category);
            } else {
                setErrors(message);
                setShowRegistration(true)
                setExistingRegistration([]);
                setName('');
                setEmail('');
                setAddress('');
                setCategory('');
            }

            setLoading(false)
        }
    }
    const registerMember = async (payLoad) => {
        setLoading(true)
        setErrors([]);

        if (payLoad.category === '2: Mjasiriamali') {
            if (payLoad.classification === null || payLoad.classification === 'undefined' || payLoad.classification === '') {
                handleClose();
                return AlertMessages(103, 'Tafadhali chagua msajili wa mjasiriamali');
            }
        }
        const res = await Axios.post(apiURL + 'create-participant', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 101) {
                const id = res.data.data.id;
                handleClose();

                navigate(`/registration-management/${id}`);
                // const swalWithBootstrapButtons = Swal.mixin({
                //     customClass: {
                //         confirmButton: 'btn btn-success', cancelButton: 'btn btn-danger'
                //     }, buttonsStyling: true
                // })
                //
                // swalWithBootstrapButtons.fire({
                //     title: 'Success', text: message, icon: 'success', showCancelButton: false, confirmButtonText: 'Ok',
                // }).then(async (result) => {
                //     if (result.isConfirmed) {
                //         window.open(`https://madinigeita.co.tz/maonesho-api/public/api/participant-details/${id}`, '_blank');
                //     }
                // })

            } else {
                setErrors(message);
            }

            setLoading(false)
        }
    }

    localStorage.removeItem("isAdmin");

    const getEvents = async () => {
        setLoading(true);
        try {
            const res = await Axios.get(apiURL + 'get-current-event', headers);
            if (res.status === 200) {
                const {status, message, events} = res.data.data;
                if (status === 100) {
                    setEvents(events);
                } else {
                    AlertMessages(status, message);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        setShowRegistration(showRegistration);
        setExistingRegistration(existingRegistration);
        setTin(tin)
    });

    function checkRegistration() {
        let sessionId = localStorage.getItem("mySessionId");
        const payLoad = {
            tin: checkTin,
            phone: checkPhone,
            session_id: sessionId
        }
        setTin(checkTin);
        setPhone(checkPhone);
        checkParticipantRegistration(payLoad).then(r => false);
    }

    return (
        <div className="bg-gray-200">
            {/* Fixed div at the top */}
            <Header/>

            {/* Adjusted content to not overlap with fixed div */}
            <div className="pt-10">
                <Divider/>

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-4 xl:grid-cols-4">
                    <div className="col-span-1 px-2 sm:col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2">
                        <ImageSlider/>
                    </div>
                    <div className="card col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2 h-full">

                        <div className='ml-3 mr-2 rounded-xl bg-white border border-gray-200'>
                            <div
                                className='flex flex-col justify-between  gap-x-4 border-b border-gray-900/5 bg-gray-50 py-3 px-6 rounded-3xl'>
                                <div>Participant Registration/Usajili wa mshiriki</div>
                                <Divider/>
                                <div className="flex justify-center mr-5 mt-5">
                                    <div className="ml-2">
                                        <InputMask
                                            mask="999-999-999"
                                            value={checkTin}
                                            onChange={(e) => {
                                                setCheckTin(e.target.value);
                                            }}
                                        >
                                            {() => (<TextField
                                                label="TIN"
                                                name="tin"
                                                fullWidth
                                                autoComplete="off" // Disable autofill
                                            />)}
                                        </InputMask>
                                    </div>
                                    <div className="ml-2">

                                        <InputMask
                                            style={{margin: 2}}
                                            mask="255 999 999 999"
                                            value={checkPhone}
                                            onChange={(e) => {
                                                setCheckPhone(e.target.value);
                                            }}
                                        >
                                            {() => (<TextField
                                                label="Phone/Simu"
                                                name="phone"
                                                fullWidth
                                                autoComplete="off" // Disable autofill
                                            />)}
                                        </InputMask>
                                    </div>

                                    {!loading &&
                                        <button onClick={checkRegistration}
                                                className="ml-2 bg-gray-500 px-4 rounded-lg border-0 text-sm text-white font-bold hover:bg-gray-400">Proceed
                                        </button>
                                    }

                                </div>
                                <Divider/>
                                {existingRegistration.id && showRegistration &&
                                    <>
                                        <div className="flex justify-center mb-2 text-sm text-blue-400">
                                            <b>{`Hello ${existingRegistration.name} of ${existingRegistration.address},`}</b>
                                        </div>
                                        <div className="flex justify-center mb-2 text-sm text-gray-700 font-bold">

                                            {`Happy to see you back. Please select your current business category and exhibition products to proceed.`}
                                        </div>
                                        <Divider/>
                                    </>

                                }
                                {currentRegistration &&
                                    <>
                                        <div className="flex justify-center mb-2 text-sm text-blue-400">
                                            Your registration for this season was completed.
                                        </div>
                                        <Divider/>
                                    </>

                                }
                                {showRegistration &&
                                    <>
                                        {!existingRegistration.id &&
                                            <>
                                                <div className="flex justify-center mb-2 text-sm text-yellow-700">
                                                    Please fill in the below form to register
                                                </div>
                                                <Divider/>
                                            </>
                                        }
                                        <div className="p-4 bg-gray-100 rounded-md">

                                            <ParticipantForm
                                                existing={existingRegistration}
                                                tin={tin}
                                                seasonId={seasonId}
                                                name={name}
                                                email={email}
                                                phone={phone}
                                                address={address}
                                                category={category}
                                                setProducts={setProducts}
                                                handleChange={handleParticipantChange}
                                                categoryOptions={businessTypes}
                                                isLoading={loading}
                                                typeDetails={businessTypesDetails}
                                                handleOptionChange={handleOptionChange}
                                                categories={mjasiliamaliCategories}
                                                selectedOption={selectedOption}
                                                setCategory={setCategory}
                                            />
                                            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                                <MKButton variant="gradient" color="secondary" onClick={handleClose}>
                                                    close
                                                </MKButton>
                                                <MKButton onClick={handleSubmit} variant="gradient" color="warning">
                                                    Save / Kamilisha usajili
                                                </MKButton>
                                            </MKBox>
                                        </div>

                                    </>
                                }

                            </div>
                        </div>
                    </div>

                </div>

                <div sx={{maxHeight: '80vh', overflow: 'auto'}}>

                </div>
            </div>
        </div>
    );
}

export default Registration;
