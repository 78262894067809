/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";

// react-router-dom components
import {useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// @mui icons
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React example components
import SimpleFooter from "examples/Footers/SimpleFooter";

// Material Kit 2 React page layout routes
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import LoginForm from "../../expo/LoginForm";
import {Config} from "../../../utils/Config";
import AlertMessages from "../../../utils/AlertMessage";
import Swal from "sweetalert2";
import Axios from "axios";
import MKButton from "../../../components/MKButton";
import {CircularProgress, List} from "@mui/material";
import {IsAdmin} from "../../../utils/isAdmin";

function SignInBasic() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    let [errors, setErrors] = useState([]);
    let [loading, setLoading] = useState(false);

    const history = useNavigate();

    let apiURL = Config.apiURL;

    useEffect(() => {
        setErrors(errors);
    }, [errors]);
    const handleParticipantChange = (e) => {
        const {name, value} = e.target;
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };
    localStorage.clear();

    const handleSubmit = () => {
        // Handle form submission and data validation
        console.log('Submitted');

            const payLoad = {
                email: email,
                password: password,
            }
            console.log(payLoad);
            userLogin(payLoad).then(r => false);

            // Reset form fields
            setPassword('');
            setEmail('');

        // Close the modal
    };
    const userLogin = async (payLoad) => {
        setLoading(true)
        setErrors([]);
        const res = await Axios.post(apiURL + 'authenticate', payLoad);
        if (res.status !== 200) {
            AlertMessages(res.data.status, res.data.message);
        } else {
            const status = res.data.data.status;
            const message = res.data.data.message;
            if (status === 100) {
                AlertMessages(status,message);
                const token = res.data.data.accessToken;
                const profile = res.data.data.user;
                localStorage.setItem("isAdmin", true);
                localStorage.setItem("accessToken", token);
                localStorage.setItem("profile", JSON.stringify(profile));

                history('/pages/system-admin/admin-panel');
            } else {
                AlertMessages(status,message);
            }
            setLoading(false)
        }
    }

    return (
        <>

            <MKBox
                position="absolute"
                top={0}
                left={0}
                zIndex={1}
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                        <Card>
                            <MKBox
                                variant="gradient"
                                bgColor="secondary"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Sign in
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={3} px={3}>
                                {errors && errors.map((record, index) => <List>
                                    <MKTypography sx={{ml: 2, mr: 2, mt: 0}} id={index} variant="body2" color="error"
                                                  fontWeight="light">
                                        <b>{record.label}:&nbsp;</b>{record.value}<br/>
                                    </MKTypography>
                                </List>)}
                                {loading && <CircularProgress/> }
                                <LoginForm email={email} handleChange={handleParticipantChange} password={password}/>
                            </MKBox>
                            <MKBox pt={0} pb={3} px={3}>
                                <MKButton sx={{width:'100%'}} onClick={handleSubmit} variant="gradient" color="secondary">
                                    Login / Ingia
                                </MKButton>
                            </MKBox>
                        </Card>
                    </Grid>
                </Grid>
            </MKBox>
            <MKBox width="100%" position="absolute" zIndex={2} bottom="1.625rem">
                <SimpleFooter light/>
            </MKBox>
        </>
    );
}

export default SignInBasic;
