import React from 'react';
import {Card, CardContent, TextField} from '@mui/material';
import Grid from "@mui/material/Grid";
import InputMask from 'react-input-mask';
import MKTypography from "../../components/MKTypography";
import {Link} from "react-router-dom";
import MKButton from "../../components/MKButton";

const SearchForm = ({searchValue, handleChange, handleSubmit}) => {

    return (<Card>
        <CardContent>
            <Grid container spacing={2} sx={{width: '350px'}}>

                <Grid item xs={12}>
                    <MKTypography variant='body2' sx={{mt:1,mb:3}} color='dark'>
                        Ingiza namba ya malipo
                    </MKTypography>
                    <MKTypography variant='body2' sx={{mt:-3,mb:0,fontSize:13}} color='dark'>
                        Enter Reference Number
                    </MKTypography>
                    <InputMask
                        mask="MG9999-999-999-999"
                        value={searchValue}
                        onChange={handleChange}
                    >
                        {() => (<TextField
                            label="Namba ya malipo / Reference Number"
                            name="search"
                            fullWidth
                        />)}
                    </InputMask>

                </Grid>
                <Grid item xs={5.3}>

                    <Link sx={{color: '#efefef'}} to="/">

                        <MKButton sx={{mt: 1}} variant="gradient" color="secondary">
                            Rudi / Back
                        </MKButton>

                    </Link>
                </Grid>
                <Grid item xs={6.7}>
                    <MKButton sx={{mt: 1}} onClick={handleSubmit} variant="gradient" color="warning">
                        Endelea/Proceed
                    </MKButton>
                </Grid>
            </Grid>
        </CardContent>
    </Card>);
};

export default SearchForm;